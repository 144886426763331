import React from 'react';
import { Badge, Group, Rating, Stack, Text, Title } from '@mantine/core';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Point } from 'tabler-icons-react';
import ProductAttribute from './ProductAttribute';
import ProductEffect from './ProductEffect';
import UserPostImageCarousel from './UserPostImageCarousel';
import { USER_POST_TYPE, USER_POST_TYPE_LIST } from '../../../config/constants';
import { mq } from '../../../config/theme';
import { stripDateOfUTC } from '../../../helpers/format';
const relativeTime = require('dayjs/plugin/relativeTime');

const UserPostDetails = ({ userPost }) => {
  dayjs.extend(relativeTime);
  const postType =
    userPost &&
    USER_POST_TYPE_LIST.find(t => t.value === userPost.fkUserPostType);

  return (
    <Stack sx={mq({ gap: 10, flex: 1 })}>
      <Stack
        sx={mq({
          gap: 5,
          overflow: 'hidden',
          marginLeft: 5
        })}
      >
        <Stack sx={{ gap: 0 }}>
          <Text
            color="grey"
            size={13}
            sx={{ flexWrap: 'nowrap', display: 'inline' }}
          >
            <Group sx={{ gap: 3, display: 'inline-flex' }}>
              <Text
                component={Link}
                sx={{
                  '&:hover': { textDecoration: 'underline' }
                }}
                to={`https://reviews.weedstrue.ca/profile/${userPost.user.username}`}
              >
                Posted by {userPost.user.username}
              </Text>
              <Point size={10} />
              <Text color="grey" sx={{ fontSize: 12 }}>
                {dayjs(`${stripDateOfUTC(userPost.created)}Z`).fromNow()}
              </Text>
              {userPost.updated && (
                <>
                  <Point size={10} />
                  <Text color="grey" sx={{ fontSize: 12 }}>
                    Updated {dayjs(userPost.updated).fromNow()}
                  </Text>
                </>
              )}
            </Group>
          </Text>
          {!userPost.hidden && (
            <Group
              sx={{
                gap: 5,
                flexWrap: 'nowrap',
                overflow: 'hidden',
                justifyContent: 'space-between',
                alignItems: 'start'
              }}
            >
              <Title
                order={4}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {userPost.title}
              </Title>
              <Badge
                color={postType.color}
                size="lg"
                sx={mq({
                  display: ['none', 'none', 'flex'],
                  minWidth: 100
                })}
                variant="filled"
              >
                {postType.label}
              </Badge>
            </Group>
          )}
        </Stack>
        <Group
          sx={mq({
            display: ['flex', 'flex', 'none']
          })}
        >
          <Badge
            color={postType.color}
            size="lg"
            sx={mq({
              display: ['flex', 'flex', 'none']
            })}
            variant="filled"
          >
            {postType.label}
          </Badge>
        </Group>
        {!userPost.hidden &&
          userPost.fkUserPostType === USER_POST_TYPE.REVIEW.value &&
          userPost.userRating && (
            <>
              <Rating readOnly value={userPost.userRating} />
              {userPost.attributes.length > 0 && (
                <Group sx={{ gap: 0 }}>
                  {userPost.attributes.map((a, index) => (
                    <React.Fragment key={a.fkProductAttributeType}>
                      <ProductAttribute
                        attribute={a}
                        sx={mq({ marginRight: [5, 5, 10] })}
                      />
                      {index !== userPost.attributes.length - 1 && (
                        <Stack sx={mq({ marginRight: [5, 5, 10] })}>
                          <Point size={10} />
                        </Stack>
                      )}
                    </React.Fragment>
                  ))}
                </Group>
              )}
              {userPost.effectTypes.length > 0 && (
                <Group sx={{ gap: 10 }}>
                  {userPost.effectTypes.map(e => (
                    <ProductEffect fkProductEffectType={e} key={e} />
                  ))}
                </Group>
              )}
            </>
          )}
        {userPost.userPostImages.length > 0 && (
          <Stack sx={{ padding: '5px 0px' }}>
            <UserPostImageCarousel userPostImages={userPost.userPostImages} />
          </Stack>
        )}
        <Text
          sx={{
            fontSize: 16,
            whiteSpace: 'pre-wrap'
          }}
        >
          {userPost.content}
        </Text>
      </Stack>
    </Stack>
  );
};

UserPostDetails.propTypes = {
  userPost: PropTypes.object
};

export default UserPostDetails;
