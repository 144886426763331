import createProvider from './createProvider';
import weedstrueAPI from '../api/weedstrueAPI';

const initialState = {
  user: { value: null, loading: false, error: '' },
  userPostContentReport: { value: null, loading: false, error: '' },
  userPostContentReports: { value: [], loading: false, error: '' }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCHING':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          loading: true,
          error: null
        }
      };
    case 'SUCCESS':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          ...action.payload,
          loading: false
        }
      };
    case 'ERROR':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          loading: false,
          error: action.payload
        }
      };
    case 'REPLACE':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: [
            ...state[action.stateName].value.filter(action.payload.filter),
            ...(Array.isArray(action.payload.value)
              ? action.payload.value
              : [action.payload.value])
          ],
          loading: false
        }
      };
    case 'FIND-REPLACE': {
      const foundIndex = state[action.stateName].value.findIndex(
        action.payload.find
      );
      if (foundIndex !== -1) {
        state[action.stateName].value[foundIndex] = action.payload.value;
      }

      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: [...state[action.stateName].value],
          loading: false
        }
      };
    }
    case 'APPEND':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: Array.isArray(action.payload)
            ? [...state[action.stateName].value, ...action.payload]
            : [...state[action.stateName].value, action.payload],
          loading: false
        }
      };
    case 'REMOVE':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: state[action.stateName].value.filter(action.payload.filter),
          loading: false
        }
      };
    case 'RESET':
      return {
        ...state,
        [action.stateName]: initialState[action.stateName]
      };
    default:
      return state;
  }
};

const getErrorMessage = error => {
  if (error.response?.status === 409) {
    return error.response?.data?.error ?? 'Oops something went wrong.';
  }
  return 'Oops something went wrong.';
};

const searchUsers =
  dispatch => async (searchTerm, onSuccessCallback, onErrorCallback) => {
    try {
      const response = await weedstrueAPI.get('/api/admin/users/search', {
        params: {
          searchTerm
        }
      });
      if (onSuccessCallback) {
        onSuccessCallback(response.data);
      }
    } catch (e) {
      if (onErrorCallback) {
        onErrorCallback(e);
      }
    }
  };

const fetchUser = dispatch => async username => {
  try {
    dispatch({
      type: 'FETCHING',
      stateName: 'user'
    });
    const response = await weedstrueAPI.get(`/api/admin/users/${username}`);

    dispatch({
      type: 'SUCCESS',
      stateName: 'user',
      payload: { value: response.data }
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      stateName: 'user',
      payload: 'Oops something went wrong.'
    });
  }
};

const fetchUserPostContentReports = dispatch => async () => {
  try {
    dispatch({
      type: 'FETCHING',
      stateName: 'userPostContentReports'
    });
    const response = await weedstrueAPI.get('/api/admin/userPosts/reports');

    dispatch({
      type: 'SUCCESS',
      stateName: 'userPostContentReports',
      payload: { value: response.data }
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      stateName: 'userPostContentReports',
      payload: 'Oops something went wrong.'
    });
  }
};

const fetchUserPostContentReport =
  dispatch => async pkUserPostContentReport => {
    try {
      dispatch({
        type: 'FETCHING',
        stateName: 'userPostContentReport'
      });
      const response = await weedstrueAPI.get(
        `/api/admin/userPosts/reports/${pkUserPostContentReport}`
      );

      dispatch({
        type: 'SUCCESS',
        stateName: 'userPostContentReport',
        payload: { value: response.data }
      });
    } catch (e) {
      dispatch({
        type: 'ERROR',
        stateName: 'userPostContentReport',
        payload: 'Oops something went wrong.'
      });
    }
  };

export const { Provider, Context } = createProvider(
  reducer,
  {
    fetchUser,
    fetchUserPostContentReport,
    fetchUserPostContentReports,
    searchUsers
  },
  initialState
);
