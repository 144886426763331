import React, { useContext, useRef, useState } from 'react';
import { Card, Stack } from '@mantine/core';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import CustomSearchItem from '../components/common/CustomSearchItem';
import SearchInput from '../components/common/SearchInput';
import UserDetails from '../components/content/users/UserDetails';
import { Context as AdminContext } from '../providers/AdminProvider';

const UsersView = () => {
  const { searchUsers } = useContext(AdminContext);
  const hasSearched = useRef(false);
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState([]);

  return (
    <Card style={{ padding: 0, flex: 1, display: 'flex' }} styles={{}}>
      <Stack sx={{ flex: 1 }}>
        <SearchInput
          data={searchData.map(u => ({
            image: u.avatar,
            label: u.username,
            value: u.username,
            description: u.email,
            showImage: true
          }))}
          itemComponent={CustomSearchItem}
          onChange={value => {
            navigate(`/users/${value}`);
            hasSearched.current = false;
          }}
          onSearch={searchTerm => {
            if (searchTerm) {
              searchUsers(searchTerm, setSearchData);
              hasSearched.current = true;
            }
          }}
          placeholder="Search users by username or email..."
          value={''}
        />
        <Routes>
          <Route element={<UserDetails />} path="/:username" />
          <Route element={<></>} path="/" />
          <Route element={<Navigate replace to="/users" />} path="*" />
        </Routes>
      </Stack>
    </Card>
  );
};

export default UsersView;
