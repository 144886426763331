import React, { useContext, useState } from 'react';
import { Button, Card, Stack, TextInput, Title } from '@mantine/core';
import { Leaf } from 'tabler-icons-react';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import FormSection from '../../common/FormSection';

const LoginView = () => {
  const { login } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    username: '',
    password: '',
    isLoading: false
  });

  return (
    <Card radius="lg" style={{ padding: 0 }}>
      <FormSection
        hideButtons
        onSubmit={() => {
          setFormState({
            ...formState,
            isLoading: true
          });
          login(
            formState,
            () => {},
            error => {
              triggerNotification(error);
              setFormState({
                ...formState,
                isLoading: false
              });
            }
          );
        }}
      >
        <Stack sx={{ padding: 20, gap: 10 }}>
          <Stack sx={{ alignSelf: 'center' }}>
            <Leaf color="dodgerblue" size={70} />
          </Stack>
          <Stack sx={{ gap: 20 }}>
            <Title order={4} sx={{ lineHeight: '18px' }}>
              Login
            </Title>
            <TextInput
              disabled={formState.isLoading}
              onChange={e =>
                setFormState({
                  ...formState,
                  username: e.currentTarget.value
                })
              }
              placeholder="admin@weedstrue.ca"
              required
              value={formState.username}
            />
            <TextInput
              disabled={formState.isLoading}
              onChange={e =>
                setFormState({
                  ...formState,
                  password: e.currentTarget.value
                })
              }
              placeholder="************"
              required
              type="password"
              value={formState.password}
            />
            <Button loading={formState.isLoading} type="submit">
              Login
            </Button>
          </Stack>
        </Stack>
      </FormSection>
    </Card>
  );
};

export default LoginView;
