import React, { useContext, useEffect, useRef } from 'react';
import {
  AppShell,
  Card,
  Divider,
  Loader,
  MantineProvider,
  Stack
} from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';
import LoginView from './components/content/auth/Login';
import Header from './components/content/navigation/Header';
import GlobalStyles from './config/GlobalStyles';
import { mq, theme } from './config/theme';
import { logger } from './helpers/logger';
import { Provider as AdminProvider } from './providers/AdminProvider';
import {
  Provider as AuthProvider,
  Context as AuthContext
} from './providers/AuthProvider';
import ReportsView from './views/ReportsView';
import UsersView from './views/UsersView';

const App = () => {
  const hasAttemptedToken = useRef(false);
  const { state, tokenLogin } = useContext(AuthContext);

  useEffect(() => {
    if (!state.tokenAttempted) {
      tokenLogin();
      hasAttemptedToken.current = true;
    }
  }, []);

  logger(state);

  return (
    <AppShell
      padding={0}
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        width: '100%',
        backgroundColor: '#4d4d4d'
      }}
    >
      {!hasAttemptedToken.current || state.loading ? (
        <Stack sx={{ margin: 'auto', justifyContent: 'center' }}>
          <Loader color="dark" />
        </Stack>
      ) : state.isAuthenticated ? (
        <Stack
          sx={mq({
            maxWidth: 1100,
            alignSelf: 'center',
            width: '100%',
            flex: [1, 1, 'unset'],
            padding: [0, 0, 20, 40],
            minHeight: ['unset', 'unset', 600]
          })}
        >
          <Card
            radius="lg"
            style={{ padding: 0, display: 'flex' }}
            sx={{ flex: 1 }}
          >
            <Stack sx={{ padding: 20, flex: 1 }}>
              <Header />
              <Divider />
              <Routes>
                <Route element={<UsersView />} path="/users/*" />
                <Route element={<ReportsView />} path="/reports/*" />
                <Route element={<Navigate replace to="/users" />} path="*" />
              </Routes>
            </Stack>
          </Card>
        </Stack>
      ) : (
        <Stack
          sx={mq({
            maxWidth: 350,
            alignSelf: 'center',
            width: '100%',
            flex: 1,
            justifyContent: 'center'
          })}
        >
          <LoginView />
        </Stack>
      )}
    </AppShell>
  );
};

export default () => (
  <AuthProvider>
    <AdminProvider>
      <MantineProvider theme={theme}>
        <Notifications position="top-right" />
        <GlobalStyles />
        <Router>
          <App />
        </Router>
      </MantineProvider>
    </AdminProvider>
  </AuthProvider>
);
