import React, { useContext, useEffect, useRef } from 'react';
import { Card, Group, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import UserPostContentReport from '../components/content/reports/UserPostContentReport';
import { stripDateOfUTC } from '../helpers/format';
import { logger } from '../helpers/logger';
import { Context as AdminContext } from '../providers/AdminProvider';

const ReportsView = () => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { state, fetchUserPostContentReports } = useContext(AdminContext);
  const { pathname } = useLocation();
  const selectedReport = pathname.split('/')[2];

  useEffect(() => {
    fetchUserPostContentReports();
    hasFetched.current = true;
  }, []);

  logger(state.userPostContentReports.value);

  return (
    <Stack sx={{ flex: 1 }}>
      <Stack sx={{ gap: 5, flex: 1, alignSelf: 'stretch' }}>
        <Text weight={500}>Reports</Text>
        <Group sx={{ flex: 1 }}>
          <Stack sx={{ flex: 1, maxWidth: 300, alignSelf: 'stretch', gap: 5 }}>
            <Card
              shadow="xl"
              style={{ padding: 0, display: 'flex' }}
              sx={{
                border: 'solid 1px lightgrey',
                flex: 1,
                alignSelf: 'stretch',
                maxWidth: 300
              }}
            >
              <Stack
                sx={{
                  flex: 1,
                  alignSelf: 'stretch',
                  overflow: 'auto',
                  gap: 0
                }}
              >
                {!hasFetched.current || state.userPostContentReports.loading ? (
                  <></>
                ) : state.userPostContentReports.value.length === 0 ? (
                  <Text sx={{ textAlign: 'center', padding: 20 }} weight={500}>
                    No Reports
                  </Text>
                ) : (
                  state.userPostContentReports.value.map(r => {
                    const isSelected =
                      selectedReport === r.pkUserPostContentReport.toString();
                    return (
                      <Group
                        key={r.pkUserPostContentReport}
                        onClick={() =>
                          navigate(`/reports/${r.pkUserPostContentReport}`)
                        }
                        sx={{
                          padding: isSelected ? 9 : 10,
                          border: isSelected ? 'solid 1px lightgrey' : 'none',
                          borderBottom: isSelected
                            ? 'solid 2px lightgrey'
                            : 'solid 1px lightgrey',
                          cursor: 'pointer',
                          '&:hover': {
                            border: 'solid 1px lightgrey',
                            borderBottom: 'solid 2px lightgrey',
                            padding: 9
                          }
                        }}
                      >
                        <Stack
                          sx={{
                            gap: 0,
                            overflow: 'hidden'
                          }}
                        >
                          <Text
                            size={14}
                            sx={{
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis'
                            }}
                            weight={500}
                          >
                            {r.userPost.title ?? 'No Title'}
                          </Text>
                          <Text size={14}>
                            {dayjs(
                              `${stripDateOfUTC(r.userPost.created)}Z`
                            ).format('MMMM D, YYYY')}
                          </Text>
                        </Stack>
                      </Group>
                    );
                  })
                )}
              </Stack>
            </Card>
          </Stack>
          <Routes>
            <Route element={<></>} path="/" />
            <Route
              element={<UserPostContentReport />}
              path="/:pkUserPostContentReport"
            />
            <Route element={<Navigate replace to="/users" />} path="*" />
          </Routes>
        </Group>
      </Stack>
    </Stack>
  );
};

export default ReportsView;
