import React, { useContext } from 'react';
import { Button, Group, Stack, Tabs } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { Context as AuthContext } from '../../../providers/AuthProvider';

const Header = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Stack>
      <Group sx={{ justifyContent: 'space-between' }}>
        <Tabs value={pathname.split('/')[1]} variant="pills">
          <Tabs.List>
            <Tabs.Tab onClick={() => navigate('/users')} value="users">
              Users
            </Tabs.Tab>
            <Tabs.Tab onClick={() => navigate('/reports')} value="reports">
              Reports
            </Tabs.Tab>
          </Tabs.List>
        </Tabs>
        <Button onClick={logout} variant="outline">
          Logout
        </Button>
      </Group>
    </Stack>
  );
};

export default Header;
