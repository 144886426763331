const PRODUCTION = true;

const AWS_COGNITO_SETTINGS = {
  Auth: {
    identityPoolId: 'us-east-1:074c2f3e-8539-49e4-9c3b-fec7d63d7bc4',
    region: 'us-east-1',
    identityPoolRegion: 'us-east-1',
    userPoolId: 'us-east-1_7qZzoWH6v',
    userPoolWebClientId: '3sjhbgb9k4m0b54j3k7vqivq9j',
    mandatorySignIn: true
  },
  Storage: {
    AWSS3: {
      bucket: 'weedstruebucket',
      region: 'us-east-1'
    }
  }
};

const S3_PUBLIC_URL = 'https://weedstruebucket.s3.amazonaws.com/public/';

const CONTENT_REPORT_TYPES = [
  {
    label: 'Explicit Content',
    value: 1
  },
  {
    label: 'Harassment',
    value: 2
  },
  {
    label: 'Hate',
    value: 3
  },
  {
    label: 'Impersonation',
    value: 4
  },
  {
    label: 'MisInformation',
    value: 5
  },
  {
    label: 'Self Harm',
    value: 6
  },
  {
    label: 'Personal Information',
    value: 7
  },
  {
    label: 'Spam',
    value: 8
  },
  {
    label: 'Threatening',
    value: 9
  }
];

const USER_POST_TYPE = {
  REVIEW: {
    value: 1,
    label: 'Review'
  },
  NEWS: {
    value: 2,
    label: 'News'
  },
  QUESTION: {
    value: 3,
    label: 'Question'
  },
  DISCUSSION: {
    value: 4,
    label: 'Discussion'
  },
  IMAGES: {
    value: 5,
    label: 'Images'
  }
};

const USER_POST_TYPE_LIST = [
  {
    value: 1,
    label: 'Review',
    color: 'blue'
  },
  {
    value: 2,
    label: 'News',
    color: 'orange'
  },
  {
    value: 3,
    label: 'Question',
    color: 'yellow'
  },
  {
    value: 4,
    label: 'Discussion',
    color: 'green'
  },
  {
    value: 5,
    label: 'Images',
    color: 'red'
  }
];

const PRODUCT_ATTRIBUTE_TYPE = [
  {
    value: 1,
    inputValue: 'thc',
    label: 'THC %',
    displayType: 'percentage',
    displayLabel: 'THC',
    userPostType: 'product-review'
  },
  {
    value: 2,
    inputValue: 'cbd',
    label: 'CBD %',
    displayType: 'percentage',
    displayLabel: 'CBD',
    userPostType: 'product-review'
  },
  {
    value: 3,
    inputValue: 'terps',
    label: 'TERPS %',
    displayType: 'percentage',
    displayLabel: 'TERPS',
    userPostType: 'product-review'
  },
  {
    value: 4,
    inputValue: 'packagedDate',
    label: 'Packaged Date',
    displayType: 'date',
    displayLabel: 'Packaged',
    userPostType: 'product-review'
  }
];

export {
  CONTENT_REPORT_TYPES,
  USER_POST_TYPE_LIST,
  USER_POST_TYPE,
  PRODUCT_ATTRIBUTE_TYPE,
  PRODUCTION,
  AWS_COGNITO_SETTINGS,
  S3_PUBLIC_URL
};
