import React, { useContext, useEffect, useRef } from 'react';
import {
  Badge,
  Button,
  Card,
  Divider,
  Group,
  Menu,
  Skeleton,
  Stack,
  Text
} from '@mantine/core';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { ChevronDown, Point } from 'tabler-icons-react';
import UserPostDetails from './UserPostDetails';
import { CONTENT_REPORT_TYPES } from '../../../config/constants';
import { getUserPostLink, stripDateOfUTC } from '../../../helpers/format';
import { logger } from '../../../helpers/logger';
import { Context as AdminContext } from '../../../providers/AdminProvider';

const UserPostContentReport = () => {
  const hasFetched = useRef(false);
  const { state, fetchUserPostContentReport } = useContext(AdminContext);
  const { pkUserPostContentReport } = useParams();
  const { value: contentReport, loading } = state.userPostContentReport;
  const contentReportType = CONTENT_REPORT_TYPES.find(
    t => t.value === contentReport?.fkContentReportType
  );
  const postLink = getUserPostLink(contentReport?.userPost);

  useEffect(() => {
    if (pkUserPostContentReport) {
      fetchUserPostContentReport(pkUserPostContentReport);
      hasFetched.current = true;
    }
  }, [pkUserPostContentReport]);

  logger(contentReport);

  return (
    <Card
      shadow="xl"
      style={{ padding: 0, display: 'flex' }}
      sx={{ border: 'solid 1px lightgrey', flex: 1, alignSelf: 'stretch' }}
    >
      <Stack sx={{ padding: 20, flex: 1 }}>
        {!hasFetched.current || loading ? (
          <>
            <Group sx={{ alignItems: 'start' }}>
              <Skeleton height={100} radius={100} width={100} />
              <Stack style={{ flex: 1, gap: 20 }}>
                <Stack sx={{ gap: 5 }}>
                  <Skeleton height={16} width={'15%'} />
                  <Skeleton height={36} width={'100%'} />
                </Stack>
                <Stack sx={{ gap: 5 }}>
                  <Skeleton height={16} width={'15%'} />
                  <Skeleton height={36} width={'100%'} />
                </Stack>
                <Stack sx={{ gap: 5 }}>
                  <Skeleton height={16} width={'15%'} />
                  <Skeleton height={86} width={'100%'} />
                </Stack>
                <Stack sx={{ gap: 5 }}>
                  <Skeleton height={16} width={'15%'} />
                  <Skeleton height={50} width={'100%'} />
                </Stack>
                <Group sx={{ gap: 15 }}>
                  <Skeleton height={16} width={'15%'} />
                  <Skeleton height={16} radius={50} width={50} />
                </Group>
              </Stack>
            </Group>
          </>
        ) : !contentReport ? (
          <>
            <Text
              color="red"
              sx={{
                textAlign: 'center',
                padding: 40
              }}
              weight={500}
            >
              Error fetching report.
            </Text>
          </>
        ) : (
          <Stack sx={{ gap: 10 }}>
            <Group
              sx={{ alignItems: 'start', justifyContent: 'space-between' }}
            >
              <Group sx={{ gap: 5 }}>
                <Badge color="red" size="lg" variant="filled">
                  {contentReportType?.label}
                </Badge>
                <Point size={14} />
                <Text size={12} weight={500}>
                  Reported{' '}
                  {dayjs(`${stripDateOfUTC(contentReport.created)}Z`).format(
                    'MMMM D, YYYY'
                  )}
                </Text>
              </Group>

              <Menu shadow="md" size="sm" width={200}>
                <Menu.Target>
                  <Button compact rightIcon={<ChevronDown />} variant="outline">
                    Actions
                  </Button>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item>Respond</Menu.Item>
                  <Menu.Item onClick={() => window.open(postLink, '_blank')}>
                    View
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
            {contentReport.extraInfo && (
              <>
                <Divider />
                <Text size={12}>{contentReport.extraInfo}</Text>
              </>
            )}
            <Divider />
            <UserPostDetails userPost={contentReport.userPost} />
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

export default UserPostContentReport;
