import React from 'react';
import { Avatar, Group, Stack, Text } from '@mantine/core';
import PropTypes from 'prop-types';

const CustomSearchItem = React.forwardRef(
  ({ image, label, description, showImage, ...rest }, ref) => {
    return (
      <Group noWrap ref={ref} sx={{ padding: 10 }} {...rest}>
        {(image || showImage) && <Avatar src={image} />}

        <Stack sx={{ gap: 0 }}>
          <Text size="sm" sx={{ lineHeight: '16px' }} weight={500}>
            {label}
          </Text>
          {description && (
            <Text opacity={0.65} size="xs" sx={{ lineHeight: '16px' }}>
              {description}
            </Text>
          )}
        </Stack>
      </Group>
    );
  }
);

CustomSearchItem.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  label: PropTypes.string,
  showImage: PropTypes.bool
};

export default CustomSearchItem;
