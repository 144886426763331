import { Auth } from '@aws-amplify/auth';
import { Storage } from '@aws-amplify/storage';
import createProvider from './createProvider';
import weedstrueAPI from '../api/weedstrueAPI';
import { AWS_COGNITO_SETTINGS } from '../config/constants';

Auth.configure(AWS_COGNITO_SETTINGS);
Storage.configure(AWS_COGNITO_SETTINGS);

const initialState = {
  username: '',
  showAuthModal: false,
  defaultAuthModalView: '',
  isAuthenticated: false,
  isAdmin: false,
  tokenAttempted: false,
  userData: null,
  userGroups: [],
  userKarma: 0,
  error: null,
  loading: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCHING':
      return {
        ...state,
        loading: true,
        error: null,
        ...(action.payload ? action.payload : {})
      };
    case 'SUCCESS':
      return {
        ...state,
        loading: false,
        error: null,
        ...action.payload
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case 'LOGOUT':
      return initialState;
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

const tokenLogin = dispatch => async () => {
  try {
    dispatch({ type: 'FETCHING', payload: { tokenAttempted: true } });
    await Auth.currentAuthenticatedUser();
    const response = await weedstrueAPI.post('/api/auth/login', null);
    window.localStorage.setItem('ageVerifiedAt', new Date().toISOString());

    if (!response.data.isAdmin) {
      await Auth.signOut();
      dispatch({ type: 'RESET' });
    } else {
      dispatch({
        type: 'SUCCESS',
        payload: {
          isAdmin: response.data.isAdmin,
          userData: response.data.user,
          userGroups: [],
          userKarma: response.data.karmaPoints,
          isAuthenticated: true,
          tokenAttempted: true
        }
      });
    }
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: null
    });
  }
};

const login =
  dispatch =>
  async ({ username, password }, onSuccessCallback, onErrorCallback) => {
    try {
      dispatch({
        type: 'SUCCESS',
        payload: {
          username
        }
      });
      await Auth.signIn(username.trim(), password.trim());
      const response = await weedstrueAPI.post('/api/auth/login', null);

      if (!response.data.isAdmin) {
        await Auth.signOut();
        dispatch({ type: 'RESET' });
        if (onErrorCallback) {
          onErrorCallback('Invalid login.');
        }
      } else {
        dispatch({
          type: 'SUCCESS',
          payload: {
            isAdmin: response.data.isAdmin,
            userData: response.data.user,
            userGroups: [],
            userKarma: response.data.karmaPoints,
            isAuthenticated: true,
            tokenAttempted: true
          }
        });
        if (onSuccessCallback) {
          onSuccessCallback();
        }
      }
    } catch (error) {
      let errorMessage = '';
      switch (error.message) {
        case 'Incorrect username or password.':
          errorMessage = 'Incorrect username or password.';
          break;
        case 'User is not confirmed.':
          errorMessage = 'User is not confirmed.';
          break;
        default:
          errorMessage = 'Oops something went wrong';
          break;
      }
      if (onErrorCallback) {
        onErrorCallback(errorMessage);
      }
      dispatch({ type: 'ERROR', payload: error });
    }
  };

const logout = dispatch => async () => {
  try {
    await Auth.signOut();
    dispatch({ type: 'RESET' });
  } catch (error) {
    dispatch({ type: 'ERROR', payload: error });
  }
};

export const { Provider, Context } = createProvider(
  reducer,
  {
    login,
    logout,
    tokenLogin
  },
  initialState
);
