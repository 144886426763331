import React, { useContext, useEffect, useRef } from 'react';
import {
  Avatar,
  Card,
  Group,
  Skeleton,
  Stack,
  Switch,
  Text,
  TextInput,
  Textarea
} from '@mantine/core';
import { useParams } from 'react-router-dom';
import { ChevronRight } from 'tabler-icons-react';
import { logger } from '../../../helpers/logger';
import { Context as AdminContext } from '../../../providers/AdminProvider';

const UserDetails = () => {
  const hasFetched = useRef(false);
  const { state, fetchUser } = useContext(AdminContext);
  const { username } = useParams();

  useEffect(() => {
    if (username) {
      fetchUser(username);
      hasFetched.current = true;
    }
  }, [username]);

  logger(state.user.value);

  return (
    <Card
      shadow="xl"
      style={{ padding: 0, display: 'flex' }}
      sx={{
        border: 'solid 1px lightgrey',
        flex: 1,
        alignSelf: 'stretch'
      }}
    >
      <Stack sx={{ padding: 20, flex: 1 }}>
        {!hasFetched.current || state.user.loading ? (
          <>
            <Group sx={{ alignItems: 'start' }}>
              <Skeleton height={100} radius={100} width={100} />
              <Stack style={{ flex: 1, gap: 20 }}>
                <Stack sx={{ gap: 5 }}>
                  <Skeleton height={16} width={'15%'} />
                  <Skeleton height={36} width={'100%'} />
                </Stack>
                <Stack sx={{ gap: 5 }}>
                  <Skeleton height={16} width={'15%'} />
                  <Skeleton height={36} width={'100%'} />
                </Stack>
                <Stack sx={{ gap: 5 }}>
                  <Skeleton height={16} width={'15%'} />
                  <Skeleton height={86} width={'100%'} />
                </Stack>
                <Stack sx={{ gap: 5 }}>
                  <Skeleton height={16} width={'15%'} />
                  <Skeleton height={50} width={'100%'} />
                </Stack>
                <Group sx={{ gap: 15 }}>
                  <Skeleton height={16} width={'15%'} />
                  <Skeleton height={16} radius={50} width={50} />
                </Group>
              </Stack>
            </Group>
          </>
        ) : !state.user.value ? (
          <>
            <Text
              color="red"
              sx={{
                textAlign: 'center',
                padding: 40
              }}
              weight={500}
            >
              Error fetching user.
            </Text>
          </>
        ) : (
          <>
            <Group sx={{ alignItems: 'start' }}>
              <Avatar radius={100} size={100} src={state.user.value.avatar} />
              <Stack style={{ flex: 1, gap: 10 }}>
                <TextInput
                  disabled
                  label="Username"
                  value={state.user.value.username}
                />
                <TextInput
                  disabled
                  label="Email"
                  value={state.user.value.email}
                />
                <Textarea
                  disabled
                  label="Bio"
                  minRows={4}
                  value={state.user.value.bio ?? ''}
                />
                <Stack sx={{ gap: 0 }}>
                  <Text size={14} weight={500}>
                    Socials
                  </Text>
                  <Stack sx={{ border: 'solid 1px lightgrey' }}>
                    {state.user.value.userSocialLinks.length === 0 ? (
                      <>
                        <Text
                          sx={{ padding: 20, textAlign: 'center' }}
                          weight={500}
                        >
                          No Socials
                        </Text>
                      </>
                    ) : (
                      state.user.value.userSocialLinks.map(s => (
                        <Group
                          component={'a'}
                          href={s.websiteUrl}
                          key={s.pkUserSocialLink}
                          sx={{
                            border: 'solid 1px lightgrey',
                            padding: 10,
                            flex: 1,
                            flexWrap: 'nowrap',
                            color: 'black',
                            textDecoration: 'none',
                            '&:hover': {
                              backgroundColor: '#fafafa'
                            }
                          }}
                          target="_blank"
                        >
                          <Group style={{ flex: 1, gap: 10 }}>
                            {s.icon}
                            <Stack sx={{ gap: 0 }}>
                              <Text sx={{ lineHeight: '16px' }} weight={500}>
                                {s.label}
                              </Text>
                              <Text
                                color="grey"
                                size={14}
                                sx={{ lineHeight: '16px' }}
                              >
                                {s.displayText}
                              </Text>
                            </Stack>
                          </Group>
                          <Group sx={{ gap: 10 }}>
                            <ChevronRight />
                          </Group>
                        </Group>
                      ))
                    )}
                  </Stack>
                </Stack>
                <Switch
                  checked={state.user.value.followersDisabled}
                  label="Followers Disabled"
                  labelPosition="left"
                  sx={{ marginTop: 10, fontWeight: 500 }}
                />
              </Stack>
            </Group>
          </>
        )}
      </Stack>
    </Card>
  );
};

export default UserDetails;
